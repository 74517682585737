import request from '@/utils/request'

export function list (data) {
  return request({
    url: 'api/dealer/list',
    method: 'get',
    params: data,
  })
}

export function setAddress (data) {
  return request({
    url: 'api/dealer/SetDealerAddress',
    method: 'post',
    data,
  })
}

export function changeIsGo (userId) {
  return request({
    url: 'api/dealer/ToggleIsGo',
    method: 'get',
    params: {
      userId
    },
  })
}
