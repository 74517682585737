<template>
  <el-card>
    <div slot="header">
      <div>经销商信息</div>
    </div>
  
    <div v-if="type=='list'">
      <el-col :span="6"
      ><el-input
        v-model="queryForm.keyworld"
        placeholder="请输入经销商相关信息进行筛选"
        clearable
      /></el-col>
      <el-button style="margin-left:20px;" type="primary" @click="getList">查询经销商</el-button>
      <el-button style="margin-left:20px;" icon="el-icon-refresh" @click="refresh">重置</el-button>
      <div style="float: right">
        <el-button size="nomal" type="primary" @click="changeType('map')">地图查看</el-button>
      </div>
      <el-table v-loading="loading" :data="list" style="margin-top:20px;">
        <el-table-column align="center" type="index" label="#" width="40"></el-table-column>
        <el-table-column align="center" prop="userName" label="经销商名称"></el-table-column>
        <el-table-column align="center" prop="companyName" label="公司名称">
          <template slot-scope="scope">
            <span>{{ (scope.row.companyName == null||scope.row.companyName == '') ? '--' : scope.row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="location" label="地址">
          <template slot-scope="scope">
            <span>{{ scope.row.location == null ? '--' : scope.row.location }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="lat" label="纬度">
          <template slot-scope="scope">
            <span>{{ scope.row.lat == null ? '--' : scope.row.lat }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="lng" label="经度">
          <template slot-scope="scope">
            <span>{{ scope.row.lng == null ? '--' : scope.row.lng }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="isGo" label="是否去过">
          <template slot-scope="scope">
            <span>{{ scope.row.isGo == null ? '否' : (scope.row.isGo == true ? '是' : '否') }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="openEditAddress(scope.row)"
              icon="el-icon-location-information"
              >分配地址</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: #67C23A"
              @click="EditisGo(scope.row)"
              icon="el-icon-edit"
              >更改状态</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryForm.total"
      >
      </el-pagination>
    </div>
    <div v-else-if="type=='map'" style="min-height:500px;">
      <div style="float: right">
        <el-button size="nomal" type="primary" @click="changeType('list')" style="margin-bottom:20px;">经销商信息表</el-button>
      </div>
      <template>
        <!-- <div id="map">
          <MapContainer :pointList="points"></MapContainer>
        </div> -->
        <div id="map">
          <div id="container" style="width:100%;height: calc(100vh - 200px);"></div>
        </div>
      </template>
    </div>
    
    <el-dialog
      title="分配地址"
      :visible.sync="addAddressDialogVisabled"
      @close="addAddressDialogClose"
    >
      <el-form
        ref="addAddressFormRef"
        :model="addAddressForm"
        :rules="addAddressFormRules"
        v-loading="loading"
        label-width="100"
      >
        <el-form-item prop="address" label="地址">
          <el-input
            type="textarea"
            v-model="addAddressForm.address"
            autocomplete="off"
            placeholder="请填写要分配的地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAddressDialogClose">取 消</el-button>
        <el-button type="primary" @click="addAddressConfim">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { list, setAddress, changeIsGo } from '@/api/Dealer/index';
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      list: [],
      loading: false,
      queryForm: {
        keyworld: '',
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      addAddressForm: {
        userId: '',
        address: ''
      },
      addAddressFormRules: {
        userId: [
          // { required: true, message: '请输入所分配的地址', trigger: 'blur' }
        ],
        address: [{ required: true, message: '请输入所分配的地址', trigger: 'blur' }],
      },
      //分配地址页
      addAddressDialogVisabled: false,
      //列表和地图页切换
      type: 'list',

      //地图部分
      winInfo: [],
      winTitle: "",
      markList: [],
      winFoot:null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    //地图部分开始
    initAMap() {
      AMapLoader.load({
          key: "4da18b25d0cd70269d75bfc69f0c02e2", // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.ToolBar','AMap.Scale'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
      .then((AMap) => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          viewMode: "2D", // 是否为3D地图模式
          zoom: 11, // 初始化地图级别
          center: [120.152575,30.266619], // 初始化地图中心点位置
        });

        var logo1 = document.getElementsByClassName('amap-logo');
        var logo2 = document.getElementsByClassName('amap-copyright');
        // console.log(logo1)
        // console.log(logo2)
        
        //深度搜索去除水印
        for (var i = 0; i < logo2.length; i++) {
            logo1[i].style.opacity = '0';
            logo2[i].style.opacity = '0';
        }

        var toolbar = new AMap.ToolBar(); //创建工具条插件实例
        this.map.addControl(toolbar); //添加工具条插件到页面
        var scale = new AMap.Scale();
        this.map.addControl(scale);

        // 创建自定义控件容器
        var controlWrapper = document.createElement('div');

        // 创建自定义控件元素
        var panControl = document.createElement('div');
        panControl.innerHTML = '<div class="amap-pan-up">∧</div>' +
          '<div>' +
          '<div class="amap-pan-left">＜</div>' +
          '<div class="amap-pan-center">＋</div>' +
          '<div class="amap-pan-right">＞</div>' +
          '</div>' +
          '<div class="amap-pan-down">∨</div>';

        panControl.style.display = 'inline-block';
        panControl.style.backgroundColor = '#fff';
        panControl.style.borderRadius = '50%';
        panControl.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.3)';
        panControl.style.padding = '5px';

        // 为所有子 div 添加样式
        var childDivs = panControl.getElementsByTagName('div');
        for (var i = 0; i < childDivs.length; i++) {
          var childDiv = childDivs[i];
          childDiv.style.width = '62px';
          childDiv.style.display = 'flex';
          childDiv.style.alignItems = 'center';
          childDiv.style.justifyContent = 'center';
          childDiv.style.cursor = 'pointer';
        }
        // 将自定义控件元素添加到控件容器
        controlWrapper.appendChild(panControl);

        // 将控件容器添加到地图容器中
        this.map.getContainer().appendChild(controlWrapper);

        // 设置自定义控件的样式
        controlWrapper.style.position = 'absolute';
        controlWrapper.style.top = '15px';
        controlWrapper.style.left = '15px';
        controlWrapper.style.width = '64px';
        controlWrapper.style.zIndex = '100';

        panControl.style.display = 'inline-block';
        // 注册控件事件
        let that = this;
        panControl.addEventListener('click', function (event) {
          var target = event.target.className;
          switch (target) {
            case 'amap-pan-up':
              that.map.panBy(0, 150); // 向上平移
              break;
            case 'amap-pan-down':
              that.map.panBy(0, -150); // 向下平移
              break;
            case 'amap-pan-left':
              that.map.panBy(180, 0); // 向左平移
              break;
            case 'amap-pan-right':
              that.map.panBy(-180, 0); // 向右平移
              break;
            case 'amap-pan-center':
              that.map.setCenter([120.152575,30.266619])
              break;
            default:
              break;
          }
        });

        // 加载多个点标记
        // 创建 AMap.Icon 实例：
        const icon = new AMap.Icon({
          imageSize: new AMap.Size(25, 34),    // 图标尺寸
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', //Icon的图像
          // image: require('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png'),  // Icon的图像
        });
        const icon2 = new AMap.Icon({
          imageSize: new AMap.Size(25, 34),    // 图标尺寸
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png', //Icon的图像
        });
        // console.log(this.list)
        //添加点标记
        for (var i = 0; i < this.list.length; i++) {
          // console.log(this.list[i].lng)
          // console.log(this.list[i].lat)
          if(this.list[i].lng&&this.list[i].lat){
            let getPosition = [this.list[i].lng,this.list[i].lat];
            // console.log(getPosition)
            let addressIcon
            if(this.list[i].isGo==true){
              addressIcon = icon;
            } else {
              addressIcon = icon2;
            }
            const marker = new AMap.Marker({
              position: getPosition,
              offset: new AMap.Pixel(-20, -40),
              icon: addressIcon, // 添加 Icon 图标 URL
              map: this.map
            });
            let that = this;
            let point = this.list[i];
            // console.log(point)
            marker.on('click', function (event) {
              let text = point.isGo==false ? '否' : '是';
              var context = ['<div style="padding-left:3px;width:180px">' +
                '<p>经销商名称: ' + point.userName + '</p>' +
                '<p>公司名称: ' + point.companyName + '</p>' +
                '<p>地址: ' + point.location + '</p>' +
                '<p>是否去过：' + text + '</p>' +
                '</div>'];
              const infoWindow = new AMap.InfoWindow({
                content: context,
                offset: new AMap.Pixel(-7, -40),
              });
              infoWindow.open(that.map, event.target.getPosition());
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    },
    //地图部分结束
    async getList() {
      this.loading = true
      let data = await list(this.queryForm);
      console.log(data)
      this.list = data.data.result;
      console.log(this.list)
      this.queryForm.pageIndex = data.data.pageIndex
      this.queryForm.pageSize = data.data.pageSize
      this.queryForm.total = data.data.total
      this.loading = false
    },
    refresh(){
      this.queryForm.keyworld = '';
      this.getList()
    },
    openEditAddress(data){
      console.log(data)
      this.addAddressForm.userId = data.userId;
      this.addAddressForm.address = data.location??'';
      this.addAddressDialogVisabled = true;
    },
    addAddressConfim(){
      console.log(this.addAddressForm)
      this.$refs.addAddressFormRef.validate(async (valid) => {
        if (valid) {
          // 表单验证通过
          console.log('表单验证通过，提交数据：', this.addAddressForm);
          if(this.addAddressForm.userId!=''){
            await setAddress(this.addAddressForm)
          }
          this.addAddressDialogVisabled = false;
          this.getList();
        } else {
          // 表单验证未通过
          console.log('表单验证未通过');
          this.$message.error('未填写分配地址，请填写后重新提交')
        }
      });
    },
    EditisGo(data){
      let text = data.isGo==false?'是' : '否'
      console.log(data)
      this.$confirm('确认把是否去过的状态将改为['+text+']?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await changeIsGo(data.userId)
          this.$message({
            type: 'success',
            message: '更改状态成功!',
          })
          this.getList()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '更改状态失败',
          })
        })
    },
    changeType(data){
      this.initAMap()
      this.type = data;
    },
    addAddressDialogClose(){
      this.addAddressDialogVisabled = false;
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryForm.pageSize = value
      this.getList()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
